/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect, useCallback } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_helpers";
import ApiStorage from "../../../../../app/ApiStorage";
import Switch from "@material-ui/core/Switch";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isObjectEmpty } from "api/utils/utils";
import ApiService, { accountManagementActions } from "api/APIService";
import {
  hideTwinPictures,
  hideFoodForm,
  hideStatus,
  hideData,
} from "../../../../../app/pages/item-details-page/duck/actions";

export function QuickUser({ messages }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [profile, setProfile] = useState({});

  const { hideFlag, hideForm, hideItemStatus, hideSystemData } = useSelector(
    (state) => ({
      hideFlag: state.itemDetails.hideTwinPictures,
      hideForm: state.itemDetails.hideFoodForm,
      hideItemStatus: state.itemDetails.hideStatus,
      hideSystemData: state.itemDetails.hideSystemData,
    }),
    shallowEqual
  );

  const { userAvatar } = useSelector(
    (state) => ({
      userAvatar: state.accountManagement.imagePreviewUrl,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!isObjectEmpty(profile)) {
      dispatch(
        accountManagementActions.readUserDocument({
          userId: profile._id,
          documentCriteria: ApiService.documentCriteria.documentType,
          documentFilter: ApiService.documentTypes.avatar,
        })
      );

      const hideTwinPicturesFlag = profile.flags.filter(
        (flag) => flag.name === "hideTwinPictures"
      );

      if (hideTwinPicturesFlag.length > 0) {
        dispatch(hideTwinPictures(hideTwinPicturesFlag[0].value));
      } else {
        dispatch(hideTwinPictures(false));
      }
      const hideFoodFormFlag = profile.flags.filter(
        (flag) => flag.name === "hideFoodForm"
      );

      if (hideFoodFormFlag.length > 0) {
        dispatch(hideFoodForm(hideFoodFormFlag[0].value));
      } else {
        dispatch(hideFoodForm(false));
      }

      const hideItemStatusFlag = profile.flags.filter(
        (flag) => flag.name === "hideItemStatus"
      );

      if (hideItemStatusFlag.length > 0) {
        dispatch(hideStatus(hideItemStatusFlag[0].value));
      } else {
        dispatch(hideStatus(false));
      }

      const hideSystemDataFlag = profile.flags.filter(
        (flag) => flag.name === "hideSystemData"
      );

      if (hideSystemDataFlag.length > 0) {
        dispatch(hideData(hideSystemDataFlag[0].value));
      } else {
        dispatch(hideData(false));
      }
    }
  }, [dispatch, profile]);

  const getUserData = useCallback(async () => {
    try {
      const userData = await ApiStorage.getSessionToken();
      const transformedData = JSON.parse(userData);
      const { userProfile } = transformedData;
      setProfile(userProfile);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  const handleChange = async (event) => {
    dispatch(hideTwinPictures(event.target.checked));
    ApiService["updateUser"]({
      flags: [{ name: "hideTwinPictures", value: event.target.checked }],
    });
    const user = await ApiStorage.getSessionToken();
    const userJson = JSON.parse(user);
    const flags = userJson.userProfile.flags.filter(
      (flag) => flag.name !== "hideTwinPictures"
    );
    userJson.userProfile.flags = [
      ...flags,
      { name: "hideTwinPictures", value: event.target.checked },
    ];
    await ApiStorage.setSessionToken(JSON.stringify(userJson));
  };

  const handleChangeFoodForm = async (event) => {
    dispatch(hideFoodForm(event.target.checked));
    ApiService["updateUser"]({
      flags: [{ name: "hideFoodForm", value: event.target.checked }],
    });

    const user = await ApiStorage.getSessionToken();
    const userJson = JSON.parse(user);
    const flags = userJson.userProfile.flags.filter(
      (flag) => flag.name !== "hideFoodForm"
    );
    userJson.userProfile.flags = [
      ...flags,
      { name: "hideFoodForm", value: event.target.checked },
    ];
    await ApiStorage.setSessionToken(JSON.stringify(userJson));
  };

  const handleChangeStatus = async (event) => {
    dispatch(hideStatus(event.target.checked));
    ApiService["updateUser"]({
      flags: [{ name: "hideItemStatus", value: event.target.checked }],
    });

    const user = await ApiStorage.getSessionToken();
    const userJson = JSON.parse(user);
    const flags = userJson.userProfile.flags.filter(
      (flag) => flag.name !== "hideItemStatus"
    );
    userJson.userProfile.flags = [
      ...flags,
      { name: "hideItemStatus", value: event.target.checked },
    ];
    await ApiStorage.setSessionToken(JSON.stringify(userJson));
  };

  const handleChangeSystemData = async (event) => {
    dispatch(hideData(event.target.checked));
    ApiService["updateUser"]({
      flags: [{ name: "hideSystemData", value: event.target.checked }],
    });

    const user = await ApiStorage.getSessionToken();
    const userJson = JSON.parse(user);
    const flags = userJson.userProfile.flags.filter(
      (flag) => flag.name !== "hideSystemData"
    );
    userJson.userProfile.flags = [
      ...flags,
      { name: "hideSystemData", value: event.target.checked },
    ];
    await ApiStorage.setSessionToken(JSON.stringify(userJson));
  };

  const pressedAccountManagement = async () => {
    try {
      const user = await ApiStorage.getSessionToken();
      const userJson = JSON.parse(user);
      if (userJson.userProfile) {
        const { authToken } = userJson;
        const { _id: userId } = userJson.userProfile;
        window.open(
          `${ApiService.ID}auth/redirect-page?userId=${userId}&authToken=${authToken}`
        );
      }
    } catch (e) {
      console.error(e, "<--- Error navigating to Account Management");
    }
  };

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          {messages.userProfile}
          {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            {userAvatar ? (
              <img
                src={userAvatar}
                alt="avatar"
                style={{ objectFit: "cover" }}
              />
            ) : (
              <div
                className="symbol-label"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/svg/avatars/001-boy.svg"
                  )})`,
                }}
              />
            )}
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {profile.name || ""}
            </a>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {profile.paymail}
                  </span>
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button className="btn btn-primary btn-bold" onClick={logoutClick}>
              {messages.signOut}
            </button>
          </div>
        </div>
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-2 mt-10">
          <p className=" m-0">
            {messages.hideTwinPictures}
            {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
          </p>
          <Switch
            checked={hideFlag}
            onChange={handleChange}
            name="checkedA"
            color="primary"
          />
        </div>
        {/*  <div className="separator separator-dashed mt-2 mb-4" />
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-2 mt-10">
          <p className=" m-0">
            {messages.hideFoodForm}
          </p>
          <Switch
            checked={hideForm}
            onChange={handleChangeFoodForm}
            name="checkedA"
            color="primary"
          />
        </div> */}
        <div className="separator separator-dashed mt-2 mb-4" />
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-2 mt-10">
          <p className=" m-0">
            {messages.hideItemStatus}
            {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
          </p>
          <Switch
            checked={hideItemStatus}
            onChange={handleChangeStatus}
            name="checkedA"
            color="primary"
          />
        </div>
        <div className="separator separator-dashed mt-2 mb-4" />
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-2 mt-10">
          <p className=" m-0">
            {messages.hideSystemData}
            {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
          </p>
          <Switch
            checked={hideSystemData}
            onChange={handleChangeSystemData}
            name="checkedA"
            color="primary"
          />
        </div>
        <div className="separator separator-dashed mt-2 mb-4" />
        <div className={classes.navButton} onClick={pressedAccountManagement}>
          <img
            alt="acm-small"
            className={classes.navIcon}
            src={toAbsoluteUrl("/media/images/acm_small.png")}
          />
          <Typography className={classes.navText}>
            {messages.manageMyAcount}
          </Typography>
        </div>
        <div className="d-flex align-items-center mt-5"></div>{" "}
        {/*

            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Chart-bar1.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Messages</div>
                  <div className="text-muted">Inbox and tasks</div>
                </div>
              </div>
            </a>

            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Files/Selected-file.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Activities</div>
                  <div className="text-muted">Logs and notifications</div>
                </div>
              </div>
            </a>

            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-opened.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Tasks</div>
                  <div className="text-muted">latest tasks and projects</div>
                </div>
              </div>
            </a>
          </div>

          <div className="separator separator-dashed my-7"></div>

          <div>
            <h5 className="mb-5">Recent Notifications</h5>

            <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-warning mr-5">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>

              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  Another purpose persuade
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-warning py-1 font-size-lg">
              +28%
            </span>
            </div>

            <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-success mr-5">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  Would be to people
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-success py-1 font-size-lg">
              +50%
            </span>
            </div>

            <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-danger mr-5">
              <SVG
                  src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group-chat.svg"
                  )}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  Purpose would be to persuade
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-danger py-1 font-size-lg">
              -27%
            </span>
            </div>

            <div className="d-flex align-items-center bg-light-info rounded p-5">
            <span className="svg-icon svg-icon-info mr-5">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>

              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  The best product
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-info py-1 font-size-lg">
              +8%
            </span>
            </div>
                  </div>*/}
      </div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  navButton: {
    alignItems: "center",
    borderRadius: "0.5rem",
    cursor: "pointer",
    marginBottom: "0.5rem",
    display: "flex",
    padding: "0.5rem",
    userSelect: "none",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  navIcon: {
    marginRight: "0.5rem",
    width: "2rem",
  },
  navText: {
    fontWeight: "100",
  },
}));
